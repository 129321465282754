function laxLoad() {
    if(!isChrome()) return;
    if(window.innerWidth < 880) return; 
    lax.addPreset("brandBlock", function() {
        return { 
            "data-lax-anchor": "#block-brand",
            "data-lax-opacity": "vh 0, 200 1",
        }
    });

    lax.setup();
	window.requestAnimationFrame(laxUpdate);
}

function laxUpdate() {
    lax.update(window.scrollY);
	window.requestAnimationFrame(laxUpdate);
}

