function menuInit() {
    addEvent('.js-scroll-to-site', scrollToSite);
    addEvent('.header-menu [href^="#section"]', function(event) {
        var target = document.querySelector(this.hash);
        if(!target) return;
        scrollTo($(target).offset().top);
    });

    window.addEventListener('hashchange', function(event) { event.preventDefault(); })
}
