function closeAll() {
    var openElements = Array.prototype.slice.call(document.querySelectorAll('.-open'));
    openElements.forEach(function(openElement) {
        openElement.classList.remove('-open');
    });
    bodyScrollLock.clearAllBodyScrollLocks();
}

function pagePrepare() {
    document.body.classList.remove('document-unload');
    laxLoad();
    formInit();
    videoInit();
    menuInit();
    slickInit();
    modalInit();
    goalInit();

    setTimeout(function() {
        document.body.classList.add('animate-ready');
        AOS.init({
            duration: 1000,
        });
    }, 1000);
}
function pageOnload() {
}
function pageOnResize() {
}
function pageOnScroll() {
}

document.addEventListener("DOMContentLoaded", pagePrepare);
window.addEventListener("load", pageOnload);
window.addEventListener("resize", pageOnResize);
window.addEventListener("scroll", pageOnScroll);
