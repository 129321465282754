function formItemFocus() {
    var formItem = this.closest('.form-field');
    if(this == document.activeElement) {
        formItem.classList.add('-focused');
    } else {
        formItem.classList.remove('-focused');
    }
}

function formItemFilled() {
    var formItem = this.closest('.form-field');
    if(this.value.trim()) {
        formItem.classList.add('-filled');
    } else {
        formItem.classList.remove('-filled');
    }
}

function formInit() {
    addEvent('.form-field__item', formItemFocus, 'focus', formItemFocus);
    addEvent('.form-field__item', formItemFocus, 'blur');
    addEvent('.form-field__item', formItemFilled, 'input', formItemFilled);
    addEvent('.form', formAjax, 'submit');
}

function formAjax(event) {
    event.preventDefault();
    var form = this;
    if (form.classList.contains('-loading'))
    return;
    var error = Array.prototype.slice.call(form.querySelectorAll(".-error"));
    error.forEach(function(item) {
        item.classList.remove('-error');
    });
    var formData = new FormData(form);
    formData.append('action', 'send_form');

    jQuery.ajax({
        url: "/form-ajax.php",
        type: "post",
        dataType: "json",
        data: formData,
        contentType: false,
        cache: false,
        processData: false,
        beforeSend: function() {
            form.classList.add('-loading')
        },
        success: function(json) {
            if (json) {
                form.classList.remove('-loading');
                if (json.status == 'security') {
                    alert(json.alert);
                } else if (json.status == 'success') {
                    if(json.success) {
                        form.classList.add('-success');
                        form.innerHTML = json.success;
                    }
                    if(json.goal) {
                        goalReach(json.goal);
                    }
                } else if (json.status == 'error') {
                    json.error.forEach(function(item) {
                        var error = form.querySelector('[name=' + item.name + ']');
                        if(!error) return;
                        var formField = error.closest('.form-field');
                        formField.classList.add('-error');
                    });
                }
            }
        }
    });
    return false;
}
