function goalInit() {
    addEvent('[href^=tel]', function() { goalReach('phone'); });
    addEvent('[href^=mailto]', function() { goalReach('email'); });
    addEvent('[href^="#"]', function() { goalReach('menu'); });
    addEvent('.js-video-play', function() { goalReach('video_play'); });
    addEvent('.js-product-link', function() { goalReach('product_link'); goalReach('product_link_'+this.dataset.product); });
    addEvent('.js-scroll-to-site', function() { goalReach('skip_welcome'); });
}

function goalReach(goal) {
    ym(56214034, 'reachGoal', goal, function() { console.log('reach goal ' + goal); });
}
