function addEvent(selector, fName, type, callback) {
    if(!type) var type = 'click';
    var elements = Array.prototype.slice.call(document.querySelectorAll(selector));
    elements.forEach(function(el) {
        el.addEventListener(type, fName);
        if(callback) callback.call(el);
    });
}

function keyDownClose(e) {
    if(e.keyCode===27) closeAll();
  }

function scrollUp() {
    scrollTo(0);   
}

function scrollTo(value) {
    jQuery('html, body').animate({
        scrollTop: value
    }, 400);    
}

function scrollToSite() {
    var blocks = Array.prototype.slice.call(document.querySelectorAll('.blocks__item'));
    var currentBlock = this.closest('.block');
    var find = false;
    var scroll = false;
    blocks.forEach(function(block) {
        if(scroll) {
            return;
        }
        if(block == currentBlock) {
            find = true;
            return;
        }
        if(find) {
            scroll = true;
            scrollTo($(block).offset().top);
            return;
        }
    });
}

function isChrome() {
    if(navigator.userAgent && navigator.userAgent.indexOf("Chrome") > -1) return true;
    return false;
}
