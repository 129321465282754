function videoPlay() {
    var videoWrap = this.closest('.js-video');
    var video = videoWrap.querySelector('video');
    if(!video) return;
    video.currentTime = 0;
    video.play();
    video.setAttribute('controls', 'controls');
    videoWrap.classList.add('-played');
}

function videoStop() {
    var videoWrap = this.closest('.js-video');
    var video = videoWrap.querySelector('video');
    if(!video) return;
    videoWrap.classList.remove('-played');
    video.pause();
    video.removeAttribute('controls');
    video.currentTime = 0;
}

function videoInit() {
    addEvent('.js-video video', videoStop, 'ended');
    addEvent('.js-video-stop', videoStop);
    addEvent('.js-video-play', videoPlay);
}
