function modalInit() {
    addEvent('[data-modal]:not([onclick]):not(.modal)', modalOpen);
    addEvent('.js-modal-close', closeAll);
    addEvent('.modal', modalClose);

    var modalProductImage = document.querySelector('.js-modal-product-image');
    if(modalProductImage) {
        var productImage = document.querySelector('.product__image');
        modalProductImage.src = modalProductImage.defaultSrc = productImage.src;
    }
}

function modalOpen() {
    closeAll();
    var target = this.dataset.modal;
    var modal = document.querySelector('.modal[data-modal="' + target + '"]');
    bodyScrollLock.disableBodyScroll(modal);
    switch (target) {
        case 'product':
            var product = this.closest('.product');
            var productTitle = product.querySelector('.product__title');
            var productSubtitle = product.querySelector('.product__subtitle');
            var productPrice = product.querySelector('.product__price-value');
            var productImage = product.querySelector('.product__image');

            var modalTitle = modal.querySelector('.js-modal-product-title');
            var modalSubtitle = modal.querySelector('.js-modal-product-subtitle');
            var modalPrice = modal.querySelector('.js-modal-product-price');
            var modalImage = modal.querySelector('.js-modal-product-image');
            var modalProduct = modal.querySelector('[name=product]');

            modalTitle.innerHTML = '';
            modalSubtitle.innerHTML = '';
            modalPrice.innerHTML = '';

            if(productTitle) modalTitle.innerHTML = productTitle.innerHTML;
            if(productSubtitle) modalSubtitle.innerHTML = productSubtitle.innerHTML;
            if(productPrice) modalPrice.innerHTML = productPrice.innerHTML;
            if(productImage) modalImage.src = productImage.src;
            else modalProductImage.src = modalProductImage.defaultSrc;
            if(modalProduct) {
                modalProduct.value = modalTitle.innerHTML + ' ' + modalSubtitle.innerHTML;
            }

            break;
    }
    modal.classList.add('-open');
}

function modalClose(event) {
    var target = event.target;
    if(target.closest('.modal__window')) return;
    closeAll();
}
