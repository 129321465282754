function slickInit() {
    $('.js-products-slider').on('init', productsSliderChange).slick({
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        rows: 0,
        prevArrow: '<div class="products-slider__arrow products-slider__arrow--prev" onclick="goalReach(\'products_slider_arrow\')"></div>',
        nextArrow: '<div class="products-slider__arrow products-slider__arrow--next" onclick="goalReach(\'products_slider_arrow\')"></div>',
        appendArrows: $('.js-products-slider-arrows'),
        slide: '.js-products-slider-slide'
    }).on('afterChange', productsSliderChange);

    addEvent('.js-product-link', productLink);
}

function productLink() {
    var link = this;
    var product = link.dataset.product;
    var wrap = link.closest('.products');
    var target = wrap.querySelector('.js-products-slider-slide[data-product="' + product + '"]');
    var slideIndex = parseInt(target.dataset.slickIndex);
    var slider = wrap.querySelector('.js-products-slider');
    $(slider).slick('slickGoTo', slideIndex);
}

function productsSliderChange(event, slick) {
    var currentProduct = slick.$slides[slick.currentSlide].dataset.product;
    var wrap = slick.$slider[0].closest('.products');
    var links = Array.prototype.slice.call(wrap.querySelectorAll('.js-product-link'));
    links.forEach(function(link) {
        if(link.dataset.product == currentProduct) {
            link.classList.add('-active');
        } else {
            link.classList.remove('-active');
        }
    });
}
